<!-- 任务详情 -->
<template>
  <div>
    <van-count-down
        v-if="watchTime"
        ref="countDown"
        style="width: 0;height: 0;overflow: hidden;"
        :time="watchTime"
        :auto-start="false"
        @finish="handleFinishStudy"
    />

    <page-container
        ref="pageContainer"
        class="mission-detail-container"
        :class="{ isVideo }"
    >
      <video
          v-if="isVideo"
          :src="missionData.videoUrl"
          controls
      />
      <div
          v-if="isRich"
          v-html="missionData.body"
      />

      <div v-if="missionVoData.contentId" class="mission-detail-bottom-bar">
        <van-button icon="cart-o" type="primary" size="small" round @click="goShopApp">
          商城
        </van-button>
        <van-button icon="thumb-circle-o" type="primary" size="small" round @click="handleThumbClick">
          点赞{{ likesCount }}
        </van-button>
        <van-button icon="share-o" type="primary" size="small" round @click="handleToShare">
          分享
        </van-button>
      </div>
    </page-container>
  </div>
</template>

<script>
import { ImagePreview, Toast } from 'vant';
import { objectGetter } from '@/util';
import { wxShareByMission } from '@/util/wxShare';
import { finishForwardTask, finishWatchTask, getMissionContentInfo, setLikeTask } from '@/api/mission';
import { MISSION_CONTENT_TYPE, MISSION_CONDITIONS } from './const';
import { getMissionDetailDataTestApi } from '@/test/missionDetail';
import { IS_LIKED_TASK, SAVE_LIKE_TASK_HISTORY } from '@/util/storage';
import { VUE_APP_SHOP_URL } from '@/config';

// userTaskId 分享、完成接口需要参数， 由任务列表页传入
// contentId 点赞接口需要参数，missionVoData离取

export default {
  name: 'missionShareDetail',
  data() {
    return {
      missionVoData: {}, // 任务
      userTaskId: '',
      link: '',
    };
  },
  computed: {
    missionData() {
      return objectGetter(this.missionVoData, 'shareInfo') || {};
    },
    isVideo() {
      return this.missionData.type === MISSION_CONTENT_TYPE.VIDEO;
    },
    isRich() {
      return this.missionData.type === MISSION_CONTENT_TYPE.RICH;
    },
    // 观看时间
    watchTime() {
      let { watchTime } = this.missionVoData || {};
      return watchTime ? watchTime * 1000 : null;
    },
    // 任务完成条件
    conditions() {
      return this.missionVoData.conditions || '';
    },
    // 完成条件：观看时长
    isWatchVideoCondition() {
      return this.conditions === MISSION_CONDITIONS.VIDEO;
    },
    // 完成条件：分享
    isWatchShareCondition() {
      return this.conditions === MISSION_CONDITIONS.SHARE;
    },
    // 点赞数
    likesCount() {
      let likes = this.missionVoData.likeNum || '';
      return likes ? `(${likes})` : '';
    },
  },
  created() {
    let { contentId, taskId, userTaskId } = this.$route.query || {};
    // contentId = 1;
    this.userTaskId = userTaskId;
    getMissionContentInfo({ contentId }).then(res => {
      this.missionVoData = res || {};
      this.handleFinishStudy();
      // let { isWatchVideoCondition, watchTime } = this;
      // // 观看时长条件
      // if (isWatchVideoCondition) {
      //   // 没有需要观看的时长，倒计时到时间后完成任务
      //   if (watchTime)
      //     this.$nextTick(this.countDownStart);
      //   else
      //     this.handleFinishStudy(); // 观看时长为空，直接完成任务
      // }
      // todo: 分享
      wxShareByMission({ ...res, contentId, taskId, userTaskId }, () => {
        // 分享成功，完成任务
        // this.handleFinishStudy();
      }, link => {
        this.link = link;
      });
    });
  },
  methods: {
    ImagePreview,
    handleFinishStudy() {
      let { missionVoData, userTaskId, isWatchVideoCondition, isWatchShareCondition } = this;
      let params = {
        contentId: missionVoData.contentId,
        userTaskId,
      };
      let service = null;
      // if (isWatchVideoCondition)
      //   service = finishWatchTask;
      // if (isWatchShareCondition)
      service = finishForwardTask;

      //todo: 调试完成任务
      service && service(params).then(res => {
        // if (res === true)
        //   this.$router.back();
      });
    },
    countDownStart() {
      this.$refs.countDown.start();
    },
    handleThumbClick() {
      if (this.handleThumbClick.loading)
        return;
      this.handleThumbClick.loading = true;
      let { missionVoData, userTaskId } = this;
      let contentId = missionVoData.contentId;
      let params = {
        contentId,
        userTaskId,
      };
      // 已点赞
      if (IS_LIKED_TASK(contentId)) {
        this.handleThumbClick.loading = false;
        Toast('您已经点赞过该任务了');
      } else {
        // 未点赞，点赞
        setLikeTask(params).then(res => {
          this.handleThumbClick.loading = false;
          let resCode = objectGetter(res, 'data.code');
          if (resCode === 0) {
            Toast('点赞成功');
            this.missionVoData.likeNum++;
            // 点赞成功，保存
            SAVE_LIKE_TASK_HISTORY(contentId);
          }
        });
      }
    },
    handleToShare() {
      this.$copyText(this.link);
      ImagePreview({
        images: ['shareText.png'],
        showIndex: false,
        closeable: true,
        maxZoom: 1,
        minZoom: 1,
      });
    },
    goShopApp() {
      window.location.href = sessionStorage.getItem('pany_toShopLink');
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$refs.countDown && this.$refs.countDown.pause();
    next();
  },
};
</script>

<style lang="less" scoped>
.mission-detail-container {
  padding: 10px 10px 54px 10px;

  /deep/ img {
    max-width: 100%;
    height: auto;
  }

  /deep/ video {
    width: 100%;
  }

  &.isVideo {
    display: flex;
    align-items: center;
    background-color: #000;
  }

  .mission-detail-bottom-bar {
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 10px;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    width: 100%;
    height: 54px;
    background-color: #fff;

    .van-button {
      padding: 0 12px;
      font-size: 16px;
    }
  }
}
</style>
